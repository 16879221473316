<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo" v-translate> Lançamento de Caixa </h1>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="modal-cadastro-form pa-3 pb-1">
              <v-row>
                <v-col dense  md="3" class="pb-0">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="birthdayMonthString"
                          :label="$gettext('Selecione uma data')"
                          @input="testeDate(birthdayMonthString)"
                          dense
                          readonly
                          v-on="on">
                          <template v-slot:append>
                            <v-icon v-on="on">
                              event
                            </v-icon>
                          </template>
                        </v-text-field>
                      </template>

                    <v-date-picker
                      v-model="birthdayMonth"
                      no-title
                      scrollable
                      locale="pt-br"
                      :type="'date'"
                      @input="closeModalDatePicker(birthdayMonth)"
                      :color="variables.colorPrimary">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="clearDateFilter(birthdayMonth)">Limpar</v-btn>
                        <v-btn text color="primary" @click="closeModalDatePicker(birthdayMonth)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col dense cols="12" md="3" sm="4" class="pb-0">
                  <v-select
                    v-model="typeFilter"
                    :items="listTypeFilter"
                    label="Filtro"
                    dense
                  />
                </v-col>

                <v-col dense cols="12" md="3" sm="4" class="pb-0" v-if="typeFilter">
                  <autoCompleteEntities
                    :entityProps.sync="entity"
                    :typesEntities="[typeFilter]"
                    :inativos="inativos"
                    :label="'Entidade'"
                  />
                </v-col>
                <v-col dense cols="12" md="3" sm="4" class="pb-0">
                  <v-select
                    v-model="typeCredit"
                    :items="typeCredits"
                    label="Mostrar por:"
                    dense
                  />
                </v-col>


              </v-row>
            </v-card-text>

            <v-card-actions class="modal-cadastro-footer pt-0">
              <v-btn
                type="submit"
                :color="variables.colorPrimary"
                @click.prevent="submit"
                class="br-btn"
                :loading="loading"
                v-translate>Visualizar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="box-table">
        
        <h1 class="titulo text-center mb-4 mt-3" v-translate>
          Relatório Lançamento de caixa <br />
          {{ birthdayMonthString }}
        </h1>

        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table class="mt-4"
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            @click:row="expandRow"
            v-model="selected"
            hide-default-footer
            :loading="loadingPag"
            show-expand
            item-key="name"
            :items-per-page.sync="listaItens.length"
            ref="dTable"
            dense
          >

              <template v-slot:item.name="{ item }">
                Nome: {{ item.name }}
              </template>

              <template v-slot:item.qtd="{ item }">
                Qtd: {{ item.qtd }}
              </template>
              <template v-slot:item.total="{ item }">
                Subtotal: {{ item.total | currency }}
              </template>

          <template v-slot:expanded-item="{ headers, k }">
          </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pl-0 pr-0">
                <div class="box-table-palpites mb-3">
                  <v-skeleton-loader
                    :transition="'fade-transition'"
                    type="table"
                    height="500">
                    <v-data-table class="mt-0 pt-0 pl-0"
                      hide-default-footer
                      :headers="nivel2"
                      dense
                      item-key="nivel2"
                      :key="'teste1231'"
                      :items="item.dados"
                      :items-per-page.sync="item.dados.length"
                    >


                        <template v-slot:item.value="{ item }">
                          {{ item.value | currency }}
                        </template>

                        <template v-slot:item.created="{ item }">
                          {{ item.created | datas('DD/MM/YYYY') }}
                        </template>

                        <template v-slot:item.type="{ item }">
                          {{ item.type === 'C' ? 'Crédito' : 'Débito' }}
                        </template>

                        <template v-slot:item.status="{ item }">
                          <v-chip class="mt-1 mb-1" :color=" item.status === 'B'? variables.colorSecondary : variables.colorPrimary"  dark>{{ item.status === 'B' ? 'Baixado' : 'Pendente' }}</v-chip>
                        </template>

                      </v-data-table>
                </v-skeleton-loader>
              </div>
            </td>
          </template>

          <!-- Add last line table -->
              <template slot="body.append">
              <tr style="background: #4CAF50; color:white;">
                  <th colspan="2" style="font-weight: 400 !important">Total Geral: {{ totalGeral.geral | currency }} </th>
                  <th style="font-weight: 400 !important">Total de Bônus: {{ totalGeral.bonus | currency }} </th>
                  <th></th>
                  <th></th>
              </tr>
            </template>

              <!-- <template v-slot:footer>
                <div class="wrapper-footer">
                  <div class="qtd-paginacao">
                    {{ pageStart }}
                    -
                    {{ pageStop }}
                    de
                    {{ totalItens }}
                  </div>

                  <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                    circle
                    :disabled="disabled"
                    :total-visible="7"
                    class="box-table-paginacao"
                    v-if="numberOfPages > 1"></v-pagination>
                </div>
              </template> -->
          </v-data-table>
        </v-skeleton-loader>

        <!-- <v-data-table
          :headers="headers"
          :items="listaItens"
          width="300px"
          height="auto"
          flat
          dense
        >

        </v-data-table> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { required  } from 'vuelidate/lib/validators'
// import Events from '@/core/service/events'
import { parseTime } from '@/core/service/utils'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import formValidations from './validation'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'relatoriosLancamentoCaixa'

moment.locale('pt-BR')

export default {
  name: 'Cadastro',
  components: {
    autoCompleteEntities: () => import('@/views/components/autoCompleteEntities')
  },
  mixins: [validationMixin, listagemTables],
  directives: {mask},
  data: () => ({
    selected: [],
    expanded: [],
    loading: false,
    loadingTable: false,
    birthdayMonthString: '',
    dtFinal: '',
    birthdayMonth: '',
    hasMonthOrDate: 1,
    menuDate: false,
    entity: {},
    typeFilter: null,
    typeCredit: 'C',
    inativos: null,
    listTypeFilter: [
      { text: 'Sem filtro', value: null },
      // { text: 'Supervisor', value: 2 },
      { text: 'Promotor', value: 3 }
    ],
    typeCredits: [
      {text: 'Créditos', value: 'C',}, { text:'Débitos', value:'D' }
    ],
    headers: [
      { sortable: false, class: 'table-header', text: '', value: 'name' },
      { sortable: false, class: 'table-header', text: '', value: 'qtd' },
      { sortable: false, class: 'table-header', text: '', value: 'total' },
    ],
    nivel2: [
      { sortable: false, class: 'table-header', text: 'Revendedor', value: 'cod' },
      { sortable: false, class: 'table-header', text: 'Ref.Lancamento', value: 'userCreated' },
      { sortable: false, class: 'table-header', text: 'Data Lançamento', value: 'created' },
      { sortable: false, class: 'table-header', text: 'Vendedor', value: 'parentName' },
      { sortable: false, class: 'table-header', text: 'Descrição', value: 'description' },
      { sortable: false, class: 'table-header', text: 'Tipo de Movimentação', value: 'type' },
      { sortable: false, class: 'table-header', text: 'Valor Lançado', value: 'value' },
      { sortable: false, class: 'table-header', text: 'Baixado por:', value: 'userUpdated' },
      { sortable: false, class: 'table-header', text: 'Situação', value: 'status' },
    ]
  }),
  mounted () {
    this.birthdayMonthString = moment().format('DD/MM/YYYY')
    this.birthdayMonth = moment().format('YYYY-MM-DD')
    // this.testeDate(this.birthdayMonth)
  },
  watch: {
    hasMonthOrDate (val) {
      this.setHasMonthOrDate(val)
    },

    typeFilter () {
      this.entity = {}
    },

    listaItens () {
      for (let i = 0; i < this.listaItens.length; i += 1) {
        const item = this.listaItens[i];
        this.$set(this.$refs.dTable.expansion, item.name, true);
      }
    }
  },

  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'totalGeral' ]),
    variables: () => variables,
    ...formValidations
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'clearItens']),
    init() {},
    expandRow(value) {
      console.log('veioa qui')
      this.expanded = value === this.expanded[0] ? [] : [value];
    },

    formatExpanded (obj) {
      if(obj[0]) {
        return obj[0].dados
      }
      return []
    },
    submit () {
      
      // if (this.$v.birthdayMonth.$invalid) { 
      //   this.$v.birthdayMonth.$touch()
      //   return false 
      // }

      this.loading = true
      const filter = {
        'PendingOperations:created_>': moment(this.birthdayMonth, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00'),
        'PendingOperations:created_<': moment(this.birthdayMonth, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59'),
        'PendingOperations:status_=': 'B',
        'EntryTypes:type_=': this.typeCredit
      }

      if (this.entity.value) { filter['PendingOperations:entityId_='] = this.entity.value }

      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao, _addlFilter: filter ? filter : undefined })
      .then(res => {
        if (!res.data.resultMap.resultGroup.length) this.msgAll('Dados não encontrados', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.msgAll(err.error, true)
      })
    },

    testeDate (birthdayMonthString) {
      const testeDate =  moment(birthdayMonthString, 'YYYY-MM-DD', true)
      if (testeDate.isValid()) {
        this.birthdayMonth = moment(birthdayMonthString, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    },

    closeModalDatePicker (date) {
      this.birthdayMonthString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    
    formatJson(filterVal) {
      let data = []
      switch (this.typeGecom) {
        case (null) :
          data = this.transmissoes.results
          break

        case (2) :
          this.transmissoes.results.forEach(item => {
            item.promotores.forEach(promotor => {
              promotor.supervisor = `${item.entity_cod} - ${item.entity_name}`
              data.push(promotor)
            })
          })

          filterVal.unshift('supervisor')
          this.headerExcel.unshift('Supervisor')
          break

        case (3) :
        case (4) :
          this.transmissoes.results.forEach(item => {
            item.promotores.forEach(promotor => {
              promotor.supervisor = `${item.entity_cod} - ${item.entity_name}`

              promotor.agencias.forEach(agencia => {
                agencia.supervisor = promotor.supervisor
                agencia.promotor = `${promotor.entity_cod} - ${promotor.entity_name}`
                data.push(agencia)
              })
            })
          })

          filterVal.unshift('promotor')
          this.headerExcel.unshift('Promotor')

          filterVal.unshift('supervisor')
          this.headerExcel.unshift('Supervisor')
          break
      }

      return data.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    }
  },

  validations () {
    return  {
      birthdayMonth: { required },
    }
  },

  beforeDestroy () {
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/modal-cadastro';
  @import '~@/assets/styles/components/table';

  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-gecom {
      .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
table tbody tr.v-data-table__expanded__row {
  background: #f1f1f1;
}
table tbody tr.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none;
  td.table-expend  {
  }
  box-shadow: none;
  padding-top: 0;
  .row {
    & > div {
      border-bottom: 1px solid #ccc;
      // border-top: 1px solid #ccc;
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
    background: #d9d9D9;
    &:nth-child(2n +1) {
      background:   #e9e9e9;
    }
    transition: 0.5;
    // border-bottom: 1px solid #ccc;
    &:hover{
      background: #F1F1F1;
    } 
  }
  .row.header-line {

    margin-top: 0;
    margin-left: -15px !important;
    margin-right: -15px !important ;
    &:last-child{
      margin-bottom: 0px;
    }
  }
  td > .header {
    margin-top: 0px;
    cursor: hand;
    background:white !important;
    div {
      font-weight: bold;
    }
    &:hover{
      background: white !important;
      
    }
  }
  td> .row.header-line {
    margin: 0;
  }
}
.wrapper-footer button {
  // height: 36px;
  // border-radius: 10px;
  // &:disabled{
  //   cursor: initial;
  //   border: none;
  //   background:#ccc !important;
  //   span{
  //     color: #666 !important;
  //   }
  // }
  // background: #1cb4ff !important;
  // height: 40px !important;
  // border-radius: 5px !important;
}
</style>
